import * as React from "react";
import Layout from "../components/layout";

const AboutPage = () => {
  return (
    <Layout pageTitle="About Me">
      <p>
        I am a software developer currently working and living in Bangalore,
        India.
      </p>
    </Layout>
  );
};

export default AboutPage
