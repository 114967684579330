import * as React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import {
  container,
  footer,
  leftHeader,
  rightHeader,
  activeLink,
  socialIcon,
  socialIconSvg,
} from "./layout.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faTwitter,
  faInstagram,
  faLinkedinIn,
  faGithub,
  faAngellist,
  faHackerNews,
  faMastodon,
} from "@fortawesome/free-brands-svg-icons"

interface LayoutProps {
  pageTitle: string
  children: React.ReactNode
}

const Layout = ({ pageTitle, children }: LayoutProps) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className={container}>
      <title>
        {data.site.siteMetadata.title} | {pageTitle}
      </title>
      <nav>
        <div className={leftHeader}>
          <Link to="/">Abhinav Kumar</Link>{" "}
          <a rel="me" href="https://mastodon.social/@abhnv" target="_blank" className={socialIcon}>
            <FontAwesomeIcon className={socialIconSvg} icon={faMastodon} size="1x" />
          </a>
          <a href="https://twitter.com/abhnvkmr" target="_blank" className={socialIcon}>
            <FontAwesomeIcon className={socialIconSvg} icon={faTwitter} size="1x" />
          </a>
          <a href="https://github.com/abhbh" target="_blank" className={socialIcon}>
            <FontAwesomeIcon className={socialIconSvg} icon={faGithub} size="1x" />
          </a>
          <a href="https://www.instagram.com/abhnvkmr" target="_blank" className={socialIcon}>
            <FontAwesomeIcon className={socialIconSvg} icon={faInstagram} size="1x" />
          </a>
          <a href="https://angel.co/abhnv" target="_blank" className={socialIcon}>
            <FontAwesomeIcon className={socialIconSvg} icon={faAngellist} size="1x" />
          </a>
          <a href="https://www.linkedin.com/in/abhnvkmr" target="_blank" className={socialIcon}>
            <FontAwesomeIcon className={socialIconSvg} icon={faLinkedinIn} size="1x" />
          </a>
        </div>
        <div className={rightHeader}>
          <Link to="/" activeClassName={activeLink}>
            Home
          </Link>

          <Link to="/blog/" activeClassName={activeLink}>
            Posts
          </Link>

          <a href="https://interrupt.substack.com" target="_blank">
            Newsletter
          </a>

          <a href="https://abhnv.com" target="_blank">
            abhnv.com
          </a>
        </div>
      </nav>
      <main>
        <h1>{pageTitle}</h1>
        {children}
      </main>
      <footer className={footer}>
        © {new Date().getFullYear()} Abhinav Kumar. Built with{" "}
        <a href="https://www.gatsbyjs.org">GatsbyJS</a>.
      </footer>
    </div>
  )
}

export default Layout
